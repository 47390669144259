'use client'
import React, { useRef } from 'react';
import { IHeaderItemProps } from './HeaderItem.types';
import { Box, Tooltip, Typography } from '@mui/material';
import Link from 'next/link';

export const HeaderItem = ({headerItem}: IHeaderItemProps) => {

    // REFS
    const linkRef = useRef<HTMLSpanElement | null>(null);

    return (
        <Tooltip
            sx={{
              color: '#000'
            }}
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: 'background.paper',
                        color: "#000",
                        borderRadius: 0,
                        py: 1
                    }
                }
            }}
            title={
                headerItem.subRoutes ?
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                    py={.5}
                >
                    {
                        headerItem.subRoutes.map((el, i) => (
                            <Link key={i} href={el.route}>
                                <Typography variant='link' color={'text.primary'} fontSize={14}>{el.name}</Typography>
                            </Link>
                        ))
                    }
                </Box>
                    : ''
            }
        >
            <Link href={headerItem.route}>
                <Typography ref={linkRef} variant='link' color={'text.primary'}>{headerItem.name}</Typography>
            </Link>
        </Tooltip>
    )


}
